import {Communication} from '../../shared/Services/communication';
import {Rechtssprechung} from '../../shared/entities/rechtssprechung';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
export class GetGetrechtssprechung extends Communication {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  getSingle(id): Observable<Rechtssprechung> {
    const url = (this.htkUrl + 'page_key=rechtssprechung&id=' + id);
    return this.httpClient.get(url) as Observable<Rechtssprechung>;
  }



  getAktuellste(): Observable<Array<Rechtssprechung>> {
    const url = (this.htkUrl + 'page_key=rechtssprechung&subService=aktuell');
    return this.httpClient.get(url) as Observable<Array<Rechtssprechung>>;
  }

  getNeueste(): Observable<Array<Rechtssprechung>> {
    const url = (this.htkUrl + 'page_key=rechtssprechung&subService=neueste');
    return this.httpClient.get(url) as Observable<Array<Rechtssprechung>>;
  }



  getAllVerdicsByPageAndSearch(page, az, gericht, text, norm, sort): Observable<Array<Rechtssprechung>> {
    let json = {
      'sort': sort,
      'query': {
        'Aktenzeichen': az,
        'Gericht': gericht,
        'Norm': norm,
        'Text': text,
      }
    };
    const url = (this.htkUrl + 'page_key=rechtssprechung&page=' + page);
    return this.httpClient.post(url, JSON.stringify(json)) as Observable<Array<Rechtssprechung>>;
  }




}
