import {Communication} from '../../shared/Services/communication';
import {FullParagraph} from '../Entities/kommentierung';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
export class GetGetgesetz extends Communication {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getSingleParagraphFull(law, paragraph): Observable<FullParagraph> {
    const url = (this.htkUrl + 'page_key=gesetz&law=' + law + ' & paragraph=' + paragraph);
    return this.httpClient.get(url) as Observable<FullParagraph>;
  }

  getAllLaws(): Observable<Array<any>> {
    const url = (this.htkUrl + 'page_key=gesetz');
    return this.httpClient.get(url) as Observable<Array<any>>;
  }

  getLawOverview(law): Observable<Array<any>> {
    const url = (this.htkUrl + 'page_key=gesetz&law=' + law);
    return this.httpClient.get(url) as Observable<Array<any>>;
  }
  getFullErlaeuterung(id): Observable<Array<any>> {
    const url = (this.htkUrl + 'page_key=gesetz&erlaeuterung=' + id);
    return this.httpClient.get(url) as Observable<Array<any>>;
  }


  NmvGetAllLaws(): Observable<any> {
    const url = 'https://www.neuer-medienverlag.de/htk2/htk-php/htkMobile.php?w=vListe&koerperschaft=d&id_projekt=1';
    //console.log("test", this.httpClient.jsonp(url, "JSONP_CALLBACK").map(resp => resp.valueOf));

    return this.httpClient.get(url) as Observable<any>;
  }
  NmvGetAllNorms(law): Observable<any> {
    const url = 'https://www.neuer-medienverlag.de/htk2/htk-php/htkMobile.php?w=gi&gesetz=' + law + '&datum=15.05.2018&id_projekt=1';
    return this.httpClient.get(url) as Observable<any>;
  }


}
