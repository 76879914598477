
import {AuthenticationService} from '../Services/AuthenticationService/authentication.service';
import {Rechtssprechung} from '../entities/rechtssprechung';
import {AuthorCommunicationService} from '../../author/Services/Communication/author-communication-service';
import {CoreCommunicationService} from '../../core/Services/CommunicationService/corecommunicationservice';
import {Input} from '@angular/core';
import {Sort} from '@angular/material';
import {Observable} from 'rxjs';
export class VerdicListEnginge {
  @Input() test;



  verdics = [] as Array<Rechtssprechung>;
  @Input() pickMyVerdics = true;
  sort = {
    active: 'Id',
    direction: 'asc'
  };
  page = 0;
  az = '';
  gericht = '';
  text = '';
  norm = '';
  constructor(protected com: CoreCommunicationService, protected user: AuthenticationService) {}

  getVerdics() {
    this.getVerdicsByPage().subscribe(
      (variabeln: any) => {

        console.log(variabeln);
        this.verdics = variabeln['result'];
      }
    );
  }

  public filterResult() {
    this.page = 0;
    this.getVerdics();

  }



  private decideWhichVerdicsToGet(): Observable<any> {
    if (!this.pickMyVerdics) {
      return this.com.getAllVerdicsByPageAndSearch(this.page, this.az, this.gericht, this.text, this.norm, this.sort);
    } else {
      return this.com.getMyVerdicsByPageAndSearch(this.page, this.az, this.gericht, this.text, this.norm, this.sort, this.user.clientInfo.clientId);
    }
  }
  private getVerdicsByPage(): Observable<any> {
    if (this.user.isAuthenticated()) {
      return this.decideWhichVerdicsToGet();
    } else {
      this.user.refreshTokenSimple().subscribe(data => {
        return this.decideWhichVerdicsToGet();
      });
    }
  }


  public forward() {
    this.page++;
    this.verdics = [];
    this.getVerdics();
  }

  public backward() {
    this.page--;
    this.verdics = [];
    this.getVerdics();
  }



  public reorder(sort: Sort) {
    this.page = 0;
    console.log(sort, 'evenst');
    this.sort = sort;
    this.getAndReorderVerdics(sort);

  }


  private getAndReorderVerdics(sort) {
    this.getVerdicsByPage().subscribe(
      (variabeln: any) => {
        if (variabeln) {
          console.log('vars', variabeln);
          this.verdics = variabeln['result'];
          const data = this.verdics.slice();

          this.verdics = data.sort((a, b) => {
            let isAsc = sort.direction === 'asc';
            switch (sort.active) {
              case 'Datum': return compare(a.Datum, b.Datum, isAsc);
              case 'Id': return compare(+a.Id, +b.Id, isAsc);
              case 'Gericht': return compare(+a.Gericht, +b.Gericht, isAsc);
              case 'Aktenzeichen': return compare(+a.Aktenzeichen, +b.Aktenzeichen, isAsc);
              default: return 0;
            }
          });

        }
      }
    );
  }

}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


