import {Communication} from '../../shared/Services/communication';
import {CoreCommunicationService} from '../../core/Services/CommunicationService/corecommunicationservice';
import {GetGetgesetz} from './getgesetz';
import {GetGetrechtssprechung} from './getrechtssprechung';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HtkCommunicationService extends CoreCommunicationService {
  gesetz: GetGetgesetz;
  rspr: GetGetrechtssprechung;
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.gesetz = new GetGetgesetz(httpClient);
    this.rspr = new GetGetrechtssprechung(httpClient);

  }
}
